<!--
 * @Author: your name
 * @Date: 2021-05-18 19:11:12
 * @LastEditTime: 2021-05-28 11:26:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\views\myfile\index.vue
-->
<template>
    <div class="allmsgclass">
        <h1>
            <a href="#input">input 输入框</a>
        </h1>
        <div id="input"></div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.allmsgclass{
    padding: .3rem;
    background-color: white;
    min-height: 100%;
}
h1{
    color: #409EFF;
}
</style>
